import Organizations from './enums/organizations'
import EMA_OR_EMI from './ema_or_emi'

const findOrganizationBySubdomain = (subdomain) => {
  // --- EMA sites ---
  if (EMA_OR_EMI === 'ema') {
    if (subdomain === undefined) {
      return Organizations.Longitudinax
    }
    switch (subdomain.trim().toLowerCase()) {
      case 'assessify':
        return Organizations.Assessify

      default:
        return Organizations.Longitudinax
    }
  } else {
    // ---- EMI sites ---
    if (EMA_OR_EMI === 'emi') {
      if (subdomain === undefined) {
        return Organizations.Longitudinax
      }
      switch (subdomain.trim().toLowerCase()) {
        default:
          return Organizations.Longitudinax
      }
    }
  }
  return Organizations.Longitudinax
}

export default findOrganizationBySubdomain
