import {
  STUDY_LIST_FAIL,
  STUDY_LIST_REQUEST,
  STUDY_LIST_SUCCESS,
  STUDY_LIST_RESET,
  STUDY_CREATE_REQUEST,
  STUDY_CREATE_SUCCESS,
  STUDY_CREATE_FAIL,
  STUDY_CREATE_RESET,
  STUDY_DELETE_REQUEST,
  STUDY_DELETE_SUCCESS,
  STUDY_DELETE_FAIL,
  STUDY_DELETE_RESET,
  STUDY_ACTIVATE_REQUEST,
  STUDY_ACTIVATE_SUCCESS,
  STUDY_ACTIVATE_FAIL,
  STUDY_DEACTIVATE_SUCCESS,
  STUDY_DEACTIVATE_FAIL,
  STUDY_DEACTIVATE_REQUEST,
  STUDY_ACTIVATE_RESET,
  STUDY_DEACTIVATE_RESET,
  STUDY_EDIT_BASIC_REQUEST,
  STUDY_EDIT_BASIC_SUCCESS,
  STUDY_EDIT_BASIC_FAIL,
  STUDY_EDIT_CONSENT_REQUEST,
  STUDY_EDIT_CONSENT_SUCCESS,
  STUDY_EDIT_CONSENT_FAIL,
  STUDY_DETAILS_REQUEST,
  STUDY_DETAILS_SUCCESS,
  STUDY_DETAILS_FAIL,
  STUDY_DETAILS_RESET,
  STUDY_EDIT_BASIC_RESET,
  STUDY_EDIT_CONSENT_RESET,
  STAGE_EDIT_BASIC_REQUEST,
  STAGE_EDIT_BASIC_SUCCESS,
  STAGE_EDIT_BASIC_FAIL,
  STAGE_EDIT_BASIC_RESET,
  STAGE_ADD_REQUEST,
  STAGE_ADD_SUCCESS,
  STAGE_ADD_FAIL,
  STAGE_ADD_RESET,
  STAGE_DELETE_REQUEST,
  STAGE_DELETE_SUCCESS,
  STAGE_DELETE_FAIL,
  STAGE_DELETE_RESET,
  PARTI_GROUP_ADD_REQUEST,
  PARTI_GROUP_ADD_SUCCESS,
  PARTI_GROUP_ADD_FAIL,
  PARTI_GROUP_ADD_RESET,
  PARTI_GROUP_DELETE_REQUEST,
  PARTI_GROUP_DELETE_SUCCESS,
  PARTI_GROUP_DELETE_FAIL,
  PARTI_GROUP_DELETE_RESET,
  PARTI_GROUP_EDIT_REQUEST,
  PARTI_GROUP_EDIT_SUCCESS,
  PARTI_GROUP_EDIT_FAIL,
  PARTI_GROUP_EDIT_RESET,
  STUDY_EDIT_EXCEPT_CONSENT_REQUEST,
  STUDY_EDIT_EXCEPT_CONSENT_SUCCESS,
  STUDY_EDIT_EXCEPT_CONSENT_FAIL,
  STUDY_EDIT_EXCEPT_CONSENT_RESET,
} from '../constants/studyConstants'

export const studyListReducer = (state = { studies: [] }, action) => {
  switch (action.type) {
    case STUDY_LIST_REQUEST:
      return { loading: true, studies: [] }
    case STUDY_LIST_SUCCESS:
      return {
        loading: false,
        studies: action.payload.studies,
        pages: action.payload.pages,
        page: action.payload.page,
        success: true,
      }
    case STUDY_LIST_FAIL:
      return { loading: false, error: action.payload }
    case STUDY_LIST_RESET:
      return { studies: [] }
    default:
      return state
  }
}

export const studyCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case STUDY_CREATE_REQUEST:
      return { loading: true }
    case STUDY_CREATE_SUCCESS:
      return { loading: false, success: true, study: action.payload }
    case STUDY_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case STUDY_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const studyActivateReducer = (state = {}, action) => {
  switch (action.type) {
    case STUDY_ACTIVATE_REQUEST:
      return { loading: true }
    case STUDY_ACTIVATE_SUCCESS:
      return { loading: false, success: true, message: action.payload.message }
    case STUDY_ACTIVATE_FAIL:
      return { loading: false, success: false, error: action.payload }
    case STUDY_ACTIVATE_RESET:
      return {}
    default:
      return state
  }
}

export const studyDectivateReducer = (state = {}, action) => {
  switch (action.type) {
    case STUDY_DEACTIVATE_REQUEST:
      return { loading: true }
    case STUDY_DEACTIVATE_SUCCESS:
      return { loading: false, success: true, message: action.payload.message }
    case STUDY_DEACTIVATE_FAIL:
      return { loading: false, success: false, error: action.payload }
    case STUDY_DEACTIVATE_RESET:
      return {}
    default:
      return state
  }
}

export const studyDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case STUDY_DELETE_REQUEST:
      return { loading: true }
    case STUDY_DELETE_SUCCESS:
      return { loading: false, success: true }
    case STUDY_DELETE_FAIL:
      return { loading: false, error: action.payload }
    case STUDY_DELETE_RESET:
      return {}
    default:
      return state
  }
}

export const studyDetailsReducer = (
  state = { study: { stage_list: [] } },
  action
) => {
  switch (action.type) {
    case STUDY_DETAILS_REQUEST:
      return { loading: true, ...state }
    case STUDY_DETAILS_SUCCESS:
      return { loading: false, success: true, study: action.payload }
    case STUDY_DETAILS_FAIL:
      return {
        loading: false,
        study: { stage_list: [] },
        error: action.payload,
      }
    case STUDY_DETAILS_RESET:
      return { study: { stage_list: [] } }
    default:
      return state
  }
}

export const studyEditConsentReducer = (state = {}, action) => {
  switch (action.type) {
    case STUDY_EDIT_CONSENT_REQUEST:
      return { loading: true }
    case STUDY_EDIT_CONSENT_SUCCESS:
      return { loading: false, success: true }
    case STUDY_EDIT_CONSENT_FAIL:
      return { loading: false, error: action.payload }
    case STUDY_EDIT_CONSENT_RESET:
      return {}
    default:
      return state
  }
}

export const stageAddReducer = (state = {}, action) => {
  switch (action.type) {
    case STAGE_ADD_REQUEST:
      return { loading: true }
    case STAGE_ADD_SUCCESS:
      return { loading: false, success: true }
    case STAGE_ADD_FAIL:
      return { loading: false, error: action.payload }
    case STAGE_ADD_RESET:
      return {}
    default:
      return state
  }
}

export const stageDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case STAGE_DELETE_REQUEST:
      return { loading: true }
    case STAGE_DELETE_SUCCESS:
      return { loading: false, success: true }
    case STAGE_DELETE_FAIL:
      return { loading: false, error: action.payload }
    case STAGE_DELETE_RESET:
      return {}
    default:
      return state
  }
}

export const participantGroupAddReducer = (state = {}, action) => {
  switch (action.type) {
    case PARTI_GROUP_ADD_REQUEST:
      return { loading: true }
    case PARTI_GROUP_ADD_SUCCESS:
      return { loading: false, success: true }
    case PARTI_GROUP_ADD_FAIL:
      return { loading: false, error: action.payload }
    case PARTI_GROUP_ADD_RESET:
      return {}
    default:
      return state
  }
}

export const participantGroupDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PARTI_GROUP_DELETE_REQUEST:
      return { loading: true }
    case PARTI_GROUP_DELETE_SUCCESS:
      return { loading: false, success: true }
    case PARTI_GROUP_DELETE_FAIL:
      return { loading: false, error: action.payload }
    case PARTI_GROUP_DELETE_RESET:
      return {}
    default:
      return state
  }
}

export const studyEditExceptConsentReducer = (state = {}, action) => {
  switch (action.type) {
    case STUDY_EDIT_EXCEPT_CONSENT_REQUEST:
      return { loading: true }
    case STUDY_EDIT_EXCEPT_CONSENT_SUCCESS:
      return { loading: false, success: true }
    case STUDY_EDIT_EXCEPT_CONSENT_FAIL:
      return { loading: false, error: action.payload }
    case STUDY_EDIT_EXCEPT_CONSENT_RESET:
      return {}
    default:
      return state
  }
}

export const studyEditBasicReducer = (state = {}, action) => {
  switch (action.type) {
    case STUDY_EDIT_BASIC_REQUEST:
      return { loading: true }
    case STUDY_EDIT_BASIC_SUCCESS:
      return { loading: false, success: true }
    case STUDY_EDIT_BASIC_FAIL:
      return { loading: false, error: action.payload }
    case STUDY_EDIT_BASIC_RESET:
      return {}
    default:
      return state
  }
}

export const stageEditBasicReducer = (state = {}, action) => {
  switch (action.type) {
    case STAGE_EDIT_BASIC_REQUEST:
      return { loading: true }
    case STAGE_EDIT_BASIC_SUCCESS:
      return { loading: false, success: true }
    case STAGE_EDIT_BASIC_FAIL:
      return { loading: false, error: action.payload }
    case STAGE_EDIT_BASIC_RESET:
      return {}
    default:
      return state
  }
}

export const participantGroupEditReducer = (state = {}, action) => {
  switch (action.type) {
    case PARTI_GROUP_EDIT_REQUEST:
      return { loading: true }
    case PARTI_GROUP_EDIT_SUCCESS:
      return { loading: false, success: true }
    case PARTI_GROUP_EDIT_FAIL:
      return { loading: false, error: action.payload }
    case PARTI_GROUP_EDIT_RESET:
      return {}
    default:
      return state
  }
}
