import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Button, Table } from 'react-bootstrap'
import Message from '../components/Message'
import ToastMessage from '../components/ToastMessage'
import Loader from '../components/Loader'
import Paginate from '../components/Paginate'

import {
  getStudyList,
  createStudy,
  deleteStudy,
  activateStudy,
  deactivateStudy,
} from '../actions/studyActions'
import {
  STUDY_ACTIVATE_RESET,
  STUDY_CREATE_RESET,
  STUDY_DEACTIVATE_RESET,
  STUDY_DELETE_RESET,
  STUDY_DETAILS_RESET,
  STUDY_LIST_RESET,
} from '../constants/studyConstants'
import {
  DOWNLOAD_TEXT_RESP_RESET,
  PARTI_LIST_RESET,
} from '../../participant_module/constants/participantConstants'
import resetErrorState from '../utils/resetErrorState'

import hasValidLoginCredential from '../../admin_module/utils/hasValidLoginCredential'
import { getAllAccess } from '../../access_module/actions/accessActions'
import { GET_ALL_ACCESS_RESET } from '../../access_module/constants/accessConstants'

const StudyListScreen = ({ history, match }) => {
  /////////////////   For pagination ///////////////////
  const pageNumber = match.params.pageNumber || 1
  const [page, setPage] = useState(pageNumber)
  /////////////////////////////////////////////////////

  // For toast message
  const [toastMessages, setToastMessages] = useState([])

  const toastOnCloseHandler = async (uid) => {
    setToastMessages((toastMessages) =>
      toastMessages.filter((message) => message.uid !== uid)
    )
  }

  const dispatch = useDispatch()

  //It is needed to check if the logged in user is an admin
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo, logout } = userLogin

  const studyList = useSelector((state) => state.studyList)
  const {
    studies,
    error: errorGetList,
    loading: loadingGetList,
    success: successGetList,
    pages,
  } = studyList

  const studyCreate = useSelector((state) => state.studyCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    study: createdStudy,
  } = studyCreate

  const studyDelete = useSelector((state) => state.studyDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = studyDelete

  const studyActivate = useSelector((state) => state.studyActivate)
  const {
    loading: loadingActivate,
    error: errorActivate,
    success: successActivate,
    message: messageActivate,
  } = studyActivate

  const studyDeactivate = useSelector((state) => state.studyDeactivate)
  const {
    loading: loadingDeactivate,
    error: errorDeactivate,
    success: successDeactivate,
    message: messageDeactivate,
  } = studyDeactivate

  const allAccess = useSelector((state) => state.allAccess)
  const {
    loading: laodingGetAllAccess,
    error: errorGetAllAccess,
    success: successGetAllAccess,
    allAccessList,
  } = allAccess

  useEffect(() => {
    if (!hasValidLoginCredential(userInfo, logout, window)) {
      dispatch({ type: STUDY_LIST_RESET })
      history.push('/login')
    } else {
      if (!loadingGetList && !successGetList && !errorGetList) {
        resetErrorState(dispatch)
        // Put the reset for getting all access just before loading the main content again
        dispatch({ type: GET_ALL_ACCESS_RESET })
        dispatch(getStudyList(pageNumber))
      }

      if (!laodingGetAllAccess && !successGetAllAccess && !errorGetAllAccess) {
        dispatch(getAllAccess())
      }

      if (successCreate) {
        dispatch({ type: STUDY_CREATE_RESET })
        dispatch({ type: STUDY_LIST_RESET })
        history.push(`/studies/${createdStudy._id}/edit`)
      }

      if (successDelete) {
        dispatch({ type: STUDY_DELETE_RESET })
        dispatch(getStudyList(pageNumber))
      }

      if (successActivate) {
        // Demo for displaying toast message
        setToastMessages((toastMessages) => [
          ...toastMessages,
          {
            uid: new Date().valueOf(),
            variant: 'info',
            message: messageActivate,
          },
        ])
        dispatch({ type: STUDY_ACTIVATE_RESET })
        dispatch(getStudyList(pageNumber))
      }

      if (successDeactivate) {
        // Demo for displaying toast message
        setToastMessages((toastMessages) => [
          ...toastMessages,
          {
            uid: new Date().valueOf(),
            variant: 'info',
            message: messageDeactivate,
          },
        ])
        dispatch({ type: STUDY_DEACTIVATE_RESET })
        dispatch(getStudyList(pageNumber))
      }
    }

    /////////////////   For pagination ///////////////////
    //pages > 0 is essential, otherwise there is an error when the list is empty
    if (pages > 0 && page > pages) {
      history.push(`/studies/${pages}`)
      dispatch({ type: STUDY_LIST_RESET })
    }
    if (pageNumber !== page) {
      dispatch({ type: STUDY_LIST_RESET })
      setPage(pageNumber)
    }
    /////////////////////////////////////////////////////
  }, [
    dispatch,
    history,
    userInfo,
    page,
    pages,
    pageNumber,
    logout,
    createdStudy,
    loadingGetList,
    laodingGetAllAccess,
    errorGetList,
    errorGetAllAccess,
    successCreate,
    successDelete,
    successGetList,
    successActivate,
    successGetAllAccess,
    messageActivate,
    successDeactivate,
    messageDeactivate,
  ])

  const goToEditHandler = async (study) => {
    dispatch({ type: STUDY_LIST_RESET })
    dispatch({ type: STUDY_DETAILS_RESET })
    history.push(`/studies/${study._id}/edit`)
  }

  const goToParticipantList = async (study) => {
    dispatch({ type: STUDY_LIST_RESET })
    dispatch({ type: PARTI_LIST_RESET })
    dispatch({ type: STUDY_DETAILS_RESET })
    dispatch({ type: DOWNLOAD_TEXT_RESP_RESET })
    history.push(`/participants/${study._id}`)
  }

  const goToCheckProgress = async (study) => {
    dispatch({ type: STUDY_LIST_RESET })
    history.push(`/participants/check_progress/${study._id}`)
  }

  const createStudyHandler = async () => {
    dispatch(createStudy())
  }

  const deleteStudyHandler = async (study) => {
    if (window.confirm('Are you sure?')) {
      resetErrorState(dispatch)
      dispatch(deleteStudy(study._id))
    }
  }

  const activateStudyHandler = async (study) => {
    dispatch(activateStudy(study._id))
  }

  const deactivateStudyHandler = async (study) => {
    dispatch(deactivateStudy(study._id))
  }

  const headingStyle = {
    fontSize: '2rem',
    fontWeight: 'bold',
  }

  return (
    <>
      {/* Display toast messages */}
      <div
        style={{
          position: 'absolute',
          backgroundColor: 'transparent',
          pointerEvents: 'none',
          height: '80vh',
          width: '80vw',
          zIndex: 1,
        }}
      >
        {toastMessages.map((toastMessage) => (
          <ToastMessage
            key={toastMessage.uid}
            variant={toastMessage.variant}
            onClose={() => toastOnCloseHandler(toastMessage.uid)}
          >
            {toastMessage.message}
          </ToastMessage>
        ))}
      </div>

      <Row className='align-items-center'>
        <Col>
          <h1 style={headingStyle}>Study List</h1>
        </Col>
        {allAccessList.find(
          (a) =>
            a.area === 'STUDY' &&
            a.modifier === 'CREATE' &&
            a.task === 'NEW_STUDY'
        ) && (
          <Col className='text-right'>
            <Button className='my-3' onClick={createStudyHandler}>
              <i className='fas fa-plus'></i> Create Study
            </Button>
          </Col>
        )}
      </Row>

      {errorDelete && <Message variant='danger'>{errorDelete}</Message>}
      {errorCreate && <Message variant='danger'>{errorCreate}</Message>}
      {errorActivate && <Message variant='danger'>{errorActivate}</Message>}
      {errorDeactivate && <Message variant='danger'>{errorDeactivate}</Message>}
      {loadingGetList ||
      loadingDelete ||
      loadingCreate ||
      loadingActivate ||
      loadingDeactivate ? (
        <Loader />
      ) : errorGetList ? (
        <Message variant='danger'>{errorGetList}</Message>
      ) : (
        <>
          <Table striped bordered hover responsive className='table-sm'>
            <thead>
              <tr>
                <th>NAME</th>
                <th>STUDY CODE</th>
                <th>STATUS</th>
                <th>CREATED AT</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {!logout &&
                studies.map((study) => (
                  <tr key={study._id}>
                    <td className='align-middle'>{study.chin_name}</td>
                    <td className='align-middle'>{study.study_code}</td>
                    <td className='align-middle'>{study.active_status}</td>
                    <td className='align-middle'>
                      {study.createdAt.substring(0, 10)}
                    </td>
                    <td className='align-middle'>
                      <Button
                        variant='primary'
                        className='btn-sm mx-1'
                        onClick={() => goToEditHandler(study)}
                      >
                        Edit
                      </Button>

                      {study.active_status === 'inactive' ? (
                        <>
                          {allAccessList.find(
                            (a) =>
                              a.area === 'STUDY' &&
                              a.modifier === 'ACTIVATE' &&
                              a.task === 'EXISTING_STUDY'
                          ) && (
                            <Button
                              variant='primary'
                              className='btn-sm mx-1'
                              onClick={() => activateStudyHandler(study)}
                            >
                              Activate
                            </Button>
                          )}
                          {allAccessList.find(
                            (a) =>
                              a.area === 'STUDY' &&
                              a.modifier === 'DELETE' &&
                              a.task === 'EXISTING_STUDY'
                          ) && (
                            <Button
                              variant='danger'
                              className='btn-sm mx-1'
                              onClick={() => deleteStudyHandler(study)}
                            >
                              Delete
                            </Button>
                          )}
                        </>
                      ) : (
                        <>
                          {allAccessList.find(
                            (a) =>
                              a.area === 'STUDY' &&
                              a.modifier === 'DEACTIVATE' &&
                              a.task === 'EXISTING_STUDY'
                          ) && (
                            <Button
                              variant='primary'
                              className='btn-sm mx-1'
                              onClick={() => deactivateStudyHandler(study)}
                            >
                              Deactivate
                            </Button>
                          )}
                        </>
                      )}

                      {study.active_status !== 'inactive' && (
                        <Button
                          variant='primary'
                          className='btn-sm mx-1'
                          onClick={() => goToParticipantList(study)}
                        >
                          Participants
                        </Button>
                      )}
                      {study.active_status !== 'inactive' && (
                        <Button
                          variant='primary'
                          className='btn-sm m-1'
                          onClick={() => goToCheckProgress(study)}
                        >
                          Progress
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <Paginate pages={pages} page={page}></Paginate>
        </>
      )}
    </>
  )
}

export default StudyListScreen
