import axios from 'axios'
import { USER_LOGOUT } from '../../admin_module/constants/userConstants'
import {
  STUDY_LIST_FAIL,
  STUDY_LIST_REQUEST,
  STUDY_LIST_SUCCESS,
  STUDY_CREATE_REQUEST,
  STUDY_CREATE_SUCCESS,
  STUDY_CREATE_FAIL,
  STUDY_DELETE_REQUEST,
  STUDY_DELETE_SUCCESS,
  STUDY_DELETE_FAIL,
  STUDY_ACTIVATE_REQUEST,
  STUDY_ACTIVATE_SUCCESS,
  STUDY_ACTIVATE_FAIL,
  STUDY_DEACTIVATE_REQUEST,
  STUDY_DEACTIVATE_SUCCESS,
  STUDY_DEACTIVATE_FAIL,
  STUDY_EDIT_BASIC_REQUEST,
  STUDY_EDIT_BASIC_SUCCESS,
  STUDY_EDIT_BASIC_FAIL,
  STUDY_EDIT_CONSENT_REQUEST,
  STUDY_EDIT_CONSENT_SUCCESS,
  STUDY_EDIT_CONSENT_FAIL,
  STUDY_DETAILS_REQUEST,
  STUDY_DETAILS_SUCCESS,
  STUDY_DETAILS_FAIL,
  STAGE_ADD_REQUEST,
  STAGE_ADD_SUCCESS,
  STAGE_ADD_FAIL,
  STAGE_EDIT_BASIC_SUCCESS,
  STAGE_EDIT_BASIC_FAIL,
  STAGE_EDIT_BASIC_REQUEST,
  STAGE_DELETE_REQUEST,
  STAGE_DELETE_SUCCESS,
  STAGE_DELETE_FAIL,
  PARTI_GROUP_ADD_REQUEST,
  PARTI_GROUP_ADD_SUCCESS,
  PARTI_GROUP_ADD_FAIL,
  PARTI_GROUP_DELETE_REQUEST,
  PARTI_GROUP_DELETE_SUCCESS,
  PARTI_GROUP_DELETE_FAIL,
  PARTI_GROUP_EDIT_REQUEST,
  PARTI_GROUP_EDIT_SUCCESS,
  PARTI_GROUP_EDIT_FAIL,
  STUDY_EDIT_EXCEPT_CONSENT_REQUEST,
  STUDY_EDIT_EXCEPT_CONSENT_SUCCESS,
  STUDY_EDIT_EXCEPT_CONSENT_FAIL,
} from '../constants/studyConstants'

import BACKEND_URL from '../../backendUrl'

const standardErrorHandling = (dispatch, error, errorConstant) => {
  if (error.response && error.response.status === 401) {
    // This ensure userInfo is clear
    dispatch({
      type: USER_LOGOUT,
    })
    localStorage.removeItem('adminModuleUserInfo')
  } else {
    // In the custom express error handler, there could be custom error message,
    //  which is stored in error.response as { message: ..., stack: ... }
    // If there is no such custom error message, output the generic error message (i.e. error.message)
    dispatch({
      type: errorConstant,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// Following are the actions that will be fired off after calling by components
export const getStudyList =
  (pageNumber = '') =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: STUDY_LIST_REQUEST })

      // getState() will return the whole redux state.
      // Check store.js combineReducer to see the key of the target state.
      const {
        userLogin: { userInfo },
      } = getState()

      //Following demostrate how to create data with axios in front-end
      //Authorization does not need to be quoted
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.get(
        BACKEND_URL + `/api/studies?pageNumber=${pageNumber}`,
        config
      )

      dispatch({
        type: STUDY_LIST_SUCCESS,
        payload: data,
      })
    } catch (error) {
      standardErrorHandling(dispatch, error, STUDY_LIST_FAIL)
    }
  }

export const createStudy = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: STUDY_CREATE_REQUEST,
    })

    // getState() will return the whole redux state.
    // Check store.js combineReducer to see the key of the target state.
    const {
      userLogin: { userInfo },
    } = getState()

    //Following demostrate how to create data with axios in front-end
    //Authorization does not need to be quoted
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(BACKEND_URL + `/api/studies`, {}, config)

    dispatch({
      type: STUDY_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    standardErrorHandling(dispatch, error, STUDY_CREATE_FAIL)
  }
}

export const activateStudy = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: STUDY_ACTIVATE_REQUEST,
    })

    // getState() will return the whole redux state.
    // Check store.js combineReducer to see the key of the target state.
    const {
      userLogin: { userInfo },
    } = getState()

    //Following demostrate how to create data with axios in front-end
    //Authorization does not need to be quoted
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(
      BACKEND_URL + `/api/studies/${id}/activate`,
      {},
      config
    )

    dispatch({
      type: STUDY_ACTIVATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    standardErrorHandling(dispatch, error, STUDY_ACTIVATE_FAIL)
  }
}

export const deactivateStudy = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: STUDY_DEACTIVATE_REQUEST,
    })

    // getState() will return the whole redux state.
    // Check store.js combineReducer to see the key of the target state.
    const {
      userLogin: { userInfo },
    } = getState()

    //Following demostrate how to create data with axios in front-end
    //Authorization does not need to be quoted
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(
      BACKEND_URL + `/api/studies/${id}/deactivate`,
      {},
      config
    )

    dispatch({
      type: STUDY_DEACTIVATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    standardErrorHandling(dispatch, error, STUDY_DEACTIVATE_FAIL)
  }
}

export const deleteStudy = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: STUDY_DELETE_REQUEST,
    })

    // getState() will return the whole redux state.
    // Check store.js combineReducer to see the key of the target state.
    const {
      userLogin: { userInfo },
    } = getState()

    //Following demostrate how to create data with axios in front-end
    //Authorization does not need to be quoted
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(BACKEND_URL + `/api/studies/${id}`, config)

    dispatch({
      type: STUDY_DELETE_SUCCESS,
    })
  } catch (error) {
    standardErrorHandling(dispatch, error, STUDY_DELETE_FAIL)
  }
}

// If something is needed to pass into an action (e.g. id), specifies it as function argument
export const listStudyDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: STUDY_DETAILS_REQUEST })

    // getState() will return the whole redux state.
    // Check store.js combineReducer to see the key of the target state.
    const {
      userLogin: { userInfo },
    } = getState()

    //Following demostrate how to create data with axios in front-end
    //Authorization does not need to be quoted
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(BACKEND_URL + `/api/studies/${id}`, config)

    dispatch({
      type: STUDY_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    standardErrorHandling(dispatch, error, STUDY_DETAILS_FAIL)
  }
}

export const editStudyConsent =
  (studyId, consent) => async (dispatch, getState) => {
    try {
      dispatch({
        type: STUDY_EDIT_CONSENT_REQUEST,
      })

      // getState() will return the whole redux state.
      // Check store.js combineReducer to see the key of the target state.
      const {
        userLogin: { userInfo },
      } = getState()

      //Following demostrate how to create data with axios in front-end
      //Authorization does not need to be quoted
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      await axios.put(
        BACKEND_URL + `/api/studies/${studyId}/edit_consent`,
        consent,
        config
      )

      dispatch({
        type: STUDY_EDIT_CONSENT_SUCCESS,
      })
    } catch (error) {
      standardErrorHandling(dispatch, error, STUDY_EDIT_CONSENT_FAIL)
    }
  }

export const addStage = (studyId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: STAGE_ADD_REQUEST,
    })

    // getState() will return the whole redux state.
    // Check store.js combineReducer to see the key of the target state.
    const {
      userLogin: { userInfo },
    } = getState()

    //Following demostrate how to create data with axios in front-end
    //Authorization does not need to be quoted
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      BACKEND_URL + `/api/studies/${studyId}/add_stage`,
      {},
      config
    )

    dispatch({
      type: STAGE_ADD_SUCCESS,
    })

    //Trigger relaod of study details
    dispatch({
      type: STUDY_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    standardErrorHandling(dispatch, error, STAGE_ADD_FAIL)
  }
}

export const deleteStage = (id, stageNum) => async (dispatch, getState) => {
  try {
    dispatch({
      type: STAGE_DELETE_REQUEST,
    })

    // getState() will return the whole redux state.
    // Check store.js combineReducer to see the key of the target state.
    const {
      userLogin: { userInfo },
    } = getState()

    //Following demostrate how to create data with axios in front-end
    //Authorization does not need to be quoted
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.delete(
      BACKEND_URL + `/api/studies/${id}/${stageNum}/delete_stage`,
      config
    )

    dispatch({
      type: STAGE_DELETE_SUCCESS,
    })

    //Trigger relaod of study details
    dispatch({
      type: STUDY_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    standardErrorHandling(dispatch, error, STAGE_DELETE_FAIL)
  }
}

export const addGroup = (stageId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PARTI_GROUP_ADD_REQUEST,
    })

    // getState() will return the whole redux state.
    // Check store.js combineReducer to see the key of the target state.
    const {
      userLogin: { userInfo },
    } = getState()

    //Following demostrate how to create data with axios in front-end
    //Authorization does not need to be quoted
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    /////////// CAUTION /////////////
    // in order for the following part to work, it has to returned the whole study
    const { data } = await axios.put(
      BACKEND_URL + `/api/studies/${stageId}/add_group`,
      {},
      config
    )

    dispatch({
      type: PARTI_GROUP_ADD_SUCCESS,
    })

    //Trigger relaod of study details
    dispatch({
      type: STUDY_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    standardErrorHandling(dispatch, error, PARTI_GROUP_ADD_FAIL)
  }
}

export const deleteGroup =
  (stageId, groupNum) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PARTI_GROUP_DELETE_REQUEST,
      })

      // getState() will return the whole redux state.
      // Check store.js combineReducer to see the key of the target state.
      const {
        userLogin: { userInfo },
      } = getState()

      //Following demostrate how to create data with axios in front-end
      //Authorization does not need to be quoted
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.delete(
        BACKEND_URL + `/api/studies/${stageId}/${groupNum}/delete_group`,
        config
      )

      dispatch({
        type: PARTI_GROUP_DELETE_SUCCESS,
      })

      //Trigger relaod of study details
      dispatch({
        type: STUDY_DETAILS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      standardErrorHandling(dispatch, error, PARTI_GROUP_DELETE_FAIL)
    }
  }

export const editStudyExceptConsent =
  (studyId, study) => async (dispatch, getState) => {
    try {
      dispatch({
        type: STUDY_EDIT_EXCEPT_CONSENT_REQUEST,
      })

      // getState() will return the whole redux state.
      // Check store.js combineReducer to see the key of the target state.
      const {
        userLogin: { userInfo },
      } = getState()

      //Following demostrate how to create data with axios in front-end
      //Authorization does not need to be quoted
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.put(
        BACKEND_URL + `/api/studies/${studyId}/edit_except_consent`,
        study,
        config
      )

      dispatch({
        type: STUDY_EDIT_EXCEPT_CONSENT_SUCCESS,
      })

      //Trigger relaod of study details
      dispatch({
        type: STUDY_DETAILS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      standardErrorHandling(dispatch, error, STUDY_EDIT_EXCEPT_CONSENT_FAIL)
    }
  }

////////////////////////////////////////////////////////////////////////
////// Tne following actions are not needed after using editStudyExceptConsent //////
////////////////////////////////////////////////////////////////////////

export const editStudyBasic =
  (studyId, studyBasic) => async (dispatch, getState) => {
    try {
      dispatch({
        type: STUDY_EDIT_BASIC_REQUEST,
      })

      // getState() will return the whole redux state.
      // Check store.js combineReducer to see the key of the target state.
      const {
        userLogin: { userInfo },
      } = getState()

      //Following demostrate how to create data with axios in front-end
      //Authorization does not need to be quoted
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.put(
        BACKEND_URL + `/api/studies/${studyId}/edit_basic`,
        studyBasic,
        config
      )

      dispatch({
        type: STUDY_EDIT_BASIC_SUCCESS,
      })

      /*
      //Trigger relaod of study details
      dispatch({
        type: STUDY_DETAILS_SUCCESS,
        payload: data,
      })
      */
    } catch (error) {
      standardErrorHandling(dispatch, error, STUDY_EDIT_BASIC_FAIL)
    }
  }

export const editStageBasic =
  (studyId, stageNum, stageBasic) => async (dispatch, getState) => {
    try {
      dispatch({
        type: STAGE_EDIT_BASIC_REQUEST,
      })

      // getState() will return the whole redux state.
      // Check store.js combineReducer to see the key of the target state.
      const {
        userLogin: { userInfo },
      } = getState()

      //Following demostrate how to create data with axios in front-end
      //Authorization does not need to be quoted
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.put(
        BACKEND_URL + `/api/studies/${studyId}/${stageNum}/edit_stage_basic`,
        stageBasic,
        config
      )

      dispatch({
        type: STAGE_EDIT_BASIC_SUCCESS,
      })

      //Trigger relaod of study details
      /*
      dispatch({
        type: STUDY_DETAILS_SUCCESS,
        payload: data,
      })
      */
    } catch (error) {
      standardErrorHandling(dispatch, error, STAGE_EDIT_BASIC_FAIL)
    }
  }

export const editGroup =
  (groupId, groupSettings) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PARTI_GROUP_EDIT_REQUEST,
      })

      // getState() will return the whole redux state.
      // Check store.js combineReducer to see the key of the target state.
      const {
        userLogin: { userInfo },
      } = getState()

      //Following demostrate how to create data with axios in front-end
      //Authorization does not need to be quoted
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.put(
        BACKEND_URL + `/api/studies/${groupId}/edit_group`,
        groupSettings,
        config
      )

      dispatch({
        type: PARTI_GROUP_EDIT_SUCCESS,
      })

      //Trigger relaod of study details
      /*
      dispatch({
        type: STUDY_DETAILS_SUCCESS,
        payload: data,
      })
      */
    } catch (error) {
      standardErrorHandling(dispatch, error, PARTI_GROUP_EDIT_FAIL)
    }
  }
