import axios from 'axios'
import { USER_LOGOUT } from '../../admin_module/constants/userConstants'
import {
  ASSIGN_PARTI_FAIL,
  ASSIGN_PARTI_REQUEST,
  ASSIGN_PARTI_SUCCESS,
  ALL_PARTI_LIST_FAIL,
  ALL_PARTI_LIST_REQUEST,
  ALL_PARTI_LIST_RESET,
  ALL_PARTI_LIST_SUCCESS,
  PARTI_LIST_FAIL,
  PARTI_LIST_REQUEST,
  PARTI_LIST_RESET,
  PARTI_LIST_SUCCESS,
  DOWNLOAD_TEXT_RESP_FAIL,
  DOWNLOAD_TEXT_RESP_REQUEST,
  DOWNLOAD_TEXT_RESP_SUCCESS,
  DOWNLOAD_IMG_RESP_REQUEST,
  DOWNLOAD_IMG_RESP_SUCCESS,
  DOWNLOAD_IMG_RESP_FAIL,
  DOWNLOAD_RESP_TIME_REQUEST,
  DOWNLOAD_RESP_TIME_SUCCESS,
  DOWNLOAD_RESP_TIME_FAIL,
  CREATE_PARTI_REQUEST,
  CREATE_PARTI_SUCCESS,
  CREATE_PARTI_FAIL,
} from '../constants/participantConstants'

import BACKEND_URL from '../../backendUrl'

const standardErrorHandling = (dispatch, error, errorConstant) => {
  if (error.response && error.response.status === 401) {
    // This ensure userInfo is clear
    dispatch({
      type: USER_LOGOUT,
    })
    localStorage.removeItem('adminModuleUserInfo')
  } else {
    // In the custom express error handler, there could be custom error message,
    //  which is stored in error.response as { message: ..., stack: ... }
    // If there is no such custom error message, output the generic error message (i.e. error.message)
    dispatch({
      type: errorConstant,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getAllPartiList = (studyId) => async (dispatch, getState) => {
  try {
    dispatch({ type: ALL_PARTI_LIST_REQUEST })

    // getState() will return the whole redux state.
    // Check store.js combineReducer to see the key of the target state.
    const {
      userLogin: { userInfo },
    } = getState()

    //Following demostrate how to create data with axios in front-end
    //Authorization does not need to be quoted
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(
      BACKEND_URL + `/api/participants/${studyId}/get_participants`,
      config
    )

    dispatch({
      type: ALL_PARTI_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    standardErrorHandling(dispatch, error, ALL_PARTI_LIST_FAIL)
  }
}

// currentStage: id of current stage
// currentGroup: either is 'all' representing all participant groups or id of current participant group
// payload data => { participants:[...] }
export const getPartiList =
  (studyId, currentStage, currentGroup) => async (dispatch, getState) => {
    try {
      dispatch({ type: PARTI_LIST_REQUEST })

      // getState() will return the whole redux state.
      // Check store.js combineReducer to see the key of the target state.
      const {
        userLogin: { userInfo },
      } = getState()

      //Following demostrate how to create data with axios in front-end
      //Authorization does not need to be quoted
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      let postBody = {}
      postBody.currentStageId = currentStage
      if (currentGroup !== 'all') {
        postBody.currentGroupId = currentGroup
      }

      const { data } = await axios.post(
        BACKEND_URL + `/api/participants/${studyId}/get_filtered_participants`,
        postBody,
        config
      )

      dispatch({
        type: PARTI_LIST_SUCCESS,
        payload: data,
      })
    } catch (error) {
      standardErrorHandling(dispatch, error, PARTI_LIST_FAIL)
    }
  }

export const assignParticipant =
  (enrolId, groupInfo) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ASSIGN_PARTI_REQUEST,
      })

      // getState() will return the whole redux state.
      // Check store.js combineReducer to see the key of the target state.
      const {
        userLogin: { userInfo },
      } = getState()

      //Following demostrate how to create data with axios in front-end
      //Authorization does not need to be quoted
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.patch(
        BACKEND_URL + `/api/participants/${enrolId}/assign_group`,
        groupInfo,
        config
      )

      dispatch({
        type: ASSIGN_PARTI_SUCCESS,
        payload: data,
      })
      // Trigger reload of participant data
      dispatch({
        type: PARTI_LIST_RESET,
      })
      dispatch({
        type: ALL_PARTI_LIST_RESET,
      })
    } catch (error) {
      standardErrorHandling(dispatch, error, ASSIGN_PARTI_FAIL)
    }
  }

export const downloadTextResp =
  (studyId, stage, group, excludedParticipantsIds) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: DOWNLOAD_TEXT_RESP_REQUEST,
      })

      // getState() will return the whole redux state.
      // Check store.js combineReducer to see the key of the target state.
      const {
        userLogin: { userInfo },
      } = getState()

      //Following demostrate how to create data with axios in front-end
      //Authorization does not need to be quoted
      const config = {
        timeout: 300000, // Set timeout to 300 seconds
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      let postBody = {}
      postBody.stageId = stage
      if (group !== 'all') {
        postBody.groupId = group
      }

      postBody.excludedParticipantsIds = excludedParticipantsIds

      const { data } = await axios.post(
        BACKEND_URL + `/api/data/${studyId}/download_text_resp`,
        postBody,
        config
      )

      let file_path = 'data:application/zip;base64,' + data.content
      let a = document.createElement('A')
      a.href = file_path
      a.download = data.title
      document.body.appendChild(a)
      setTimeout(() => {
        a.click()
        document.body.removeChild(a)

        dispatch({
          type: DOWNLOAD_TEXT_RESP_SUCCESS,
          payload: data,
        })
      }, 1000)
    } catch (error) {
      standardErrorHandling(dispatch, error, DOWNLOAD_TEXT_RESP_FAIL)
    }
  }

export const downloadRespTime =
  (studyId, stage, group) => async (dispatch, getState) => {
    try {
      dispatch({
        type: DOWNLOAD_RESP_TIME_REQUEST,
      })

      // getState() will return the whole redux state.
      // Check store.js combineReducer to see the key of the target state.
      const {
        userLogin: { userInfo },
      } = getState()

      //Following demostrate how to create data with axios in front-end
      //Authorization does not need to be quoted
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      let postBody = {}
      postBody.stageId = stage
      if (group !== 'all') {
        postBody.groupId = group
      }

      const { data } = await axios.post(
        BACKEND_URL + `/api/data/${studyId}/download_resp_time`,
        postBody,
        config
      )

      let file_path =
        'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' +
        data.content
      let a = document.createElement('A')
      a.href = file_path
      a.download = data.title
      document.body.appendChild(a)
      setTimeout(() => {
        a.click()
        document.body.removeChild(a)

        dispatch({
          type: DOWNLOAD_RESP_TIME_SUCCESS,
          payload: data,
        })
      }, 1000)
    } catch (error) {
      standardErrorHandling(dispatch, error, DOWNLOAD_RESP_TIME_FAIL)
    }
  }

export const createParticipant =
  (studyId, email, password) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CREATE_PARTI_REQUEST,
      })

      // getState() will return the whole redux state.
      // Check store.js combineReducer to see the key of the target state.
      const {
        userLogin: { userInfo },
      } = getState()

      //Following demostrate how to create data with axios in front-end
      //Authorization does not need to be quoted
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        BACKEND_URL + `/api/participants/${studyId}/create`,
        { studyId, email, password },
        config
      )

      dispatch({
        type: CREATE_PARTI_SUCCESS,
        payload: data,
      })
      // Trigger reload of participant data
      dispatch({
        type: PARTI_LIST_RESET,
      })
      dispatch({
        type: ALL_PARTI_LIST_RESET,
      })
    } catch (error) {
      standardErrorHandling(dispatch, error, CREATE_PARTI_FAIL)
    }
  }
