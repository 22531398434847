import FormContainer from '../components/FormContainer'
import { Row, Col } from 'react-bootstrap'

const headingStyle = {
  fontSize: '2rem',
  fontWeight: 'bold',
  marginBottom: '1rem',
}

const heading2Style = {
  fontSize: '1.2rem',
  fontWeight: 'bold',
  marginBottom: '0.75rem',
  marginTop: '2rem',
}

const ParticipantSupportScreen = () => {
  return (
    <FormContainer>
      <Row>
        <Col>
          <br></br>
          <h2 style={heading2Style}>OVERVIEW</h2>
          <p>
            ‘Assessify’ is an app created by the Department of Applied Social
            Sciences, The Hong Kong Polytechnic University. It is for users to
            participate in academic research remotely.
          </p>

          <h2 style={heading2Style}>INSTRUCTIONS FOR USING THE APP</h2>
          <p>1. Click on the Apple app store button.</p>
          <p>2. Search ‘Assessify’.</p>
          <p>3. Click on the ‘Get’ button to download the app.</p>
          <p>4. Follow the prompts.</p>
          <p>
            5. After downloading the app, create an account with your email
            address.
          </p>
          <p>6. Enter a study code to join the study.</p>
          <p>
            7. Click on the ‘assessment’ tab of the app and complete the consent
            form.
          </p>
          <p>8. You will receive notifications about the study.</p>
          <p>
            9. If you want to change your password, click on ‘Change password’
            in the Settings.
          </p>
          <p>
            10. If you forgot your password, click on ‘Forgot password’ in the
            login page.
          </p>
          <h2 style={heading2Style}>CONTACT FOR GENERAL ENQUIRY</h2>
          <p>Project coordinator</p>
          <p>Tel: (+852) 6232 - 6684 </p>
          <p>Email: fam.health@polyu.edu.hk</p>
        </Col>
      </Row>
    </FormContainer>
  )
}

export default ParticipantSupportScreen
