import EMA_OR_EMI from './ema_or_emi'

const getLxProSettings = (rootUrl) => {
  return {
    faviconUrl: rootUrl + '/longitudinax/favicon.ico',
    primaryColor: 'orange',
    theme: 'light',
    siteName: 'Assessify',
    siteLogoUrl: '',
    privacyUrl:
      'https://policy.infinite6.net/longitudinax-pro-privacy-policy.html',
    termsOfUseUrl:
      'https://policy.infinite6.net/longitudinax-pro-terms-cond.html',
  }
}

const getLxEMISettings = (rootUrl) => {
  return {
    faviconUrl: rootUrl + '/longitudinax/favicon.ico',
    primaryColor: 'green',
    theme: 'dark',
    siteName: 'Longitudinax',
    siteLogoUrl: '',
    privacyUrl:
      'https://policy.infinite6.net/longitudinax-pro-privacy-policy.html',
    termsOfUseUrl:
      'https://policy.infinite6.net/longitudinax-pro-terms-cond.html',
  }
}

const findSiteInfoBySubdomain = (subdomain) => {
  let root_url_until_ind = process.env.PUBLIC_URL.indexOf('/')
  let root_url = process.env.PUBLIC_URL
  if (root_url_until_ind !== -1) {
    root_url = root_url.substring(0, root_url_until_ind)
  }

  // -- EMA Sites --
  if (EMA_OR_EMI === 'ema') {
    if (subdomain === undefined) {
      return getLxProSettings(root_url)
    }
    switch (subdomain.trim().toLowerCase()) {
      case 'assessify': //To do: fill out all the information before release
        return {
          faviconUrl: root_url + '/assessify/favicon.ico',
          primaryColor: '#75c68b',
          theme: 'dark',
          siteName: 'Assessify',
          siteLogoUrl: '',
          privacyUrl: '',
          termsOfUseUrl: '',
        }

      default:
        return getLxProSettings(root_url)
    }
  }

  // -- EMI Sites ==
  if (EMA_OR_EMI === 'emi') {
    // To do: modify it such that it looks different from Longitudinax Pro
    if (subdomain === undefined) {
      return getLxEMISettings(root_url)
    }
    switch (subdomain.trim().toLowerCase()) {
      default:
        return getLxEMISettings(root_url)
    }
  }
}

export default findSiteInfoBySubdomain
